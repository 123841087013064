import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"

import SEO from "../components/seo"
import SplitSection from "../components/SplitSection"

const Title = styled.h2`
  font-family: "Minion Pro";
  font-size: 14.2pt;
  letter-spacing: 0.25em;
  margin-bottom: 15px;
`

const Subtitle = styled.h3`
  margin-top: 10px;
  font-family: "Minion Pro";
  font-size: 10.6pt;
  text-transform: uppercase;
`

const AddressLine = styled.p`
  font-size: 10.6pt;
  line-height: 13pt;
  font-family: "Minion Pro";
  font-style: italic;
`

const ContactName = styled.p`
  margin-top: 10px;
  font-family: "Minion Pro";
  font-weight: bold;
  font-style: italic;
  font-size: 10.6pt;
`

const ContactDetail = styled.a`
  font-family: "Minion Pro";
  font-style: italic;
  font-size: 10.6pt;
  line-height: 13pt;
`

const FooterText = styled.div`
  font-size: 12pt;
  font-family: "Minion Pro";
  margin-top: 10px;
`

type Props = {
  data: {
    file: IGatsbyImageData
  }
}

const Contact = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title="Contact" />
      <SplitSection image={data.file} imagePosition="left">
        <Title>HELLO / BONJOUR</Title>
        <AddressLine>23, rue Fourcroy</AddressLine>
        <AddressLine>75017 Paris</AddressLine>

        <Subtitle>Agents</Subtitle>
        <ContactName>Valéry GUIBAL</ContactName>
        <ContactDetail href="mailto:vbg@filmtalents.com">
          vbg@filmtalents.com
        </ContactDetail>

        <ContactName>Simon REY</ContactName>
        <ContactDetail href="mailto:simon@filmtalents.com">
          simon@filmtalents.com
        </ContactDetail>

        <ContactName>Sylvain COCHARD</ContactName>
        <ContactDetail href="mailto:sylvain@filmtalents.com">
          sylvain@filmtalents.com
        </ContactDetail>
        <ContactDetail>+33 (0)6 43 75 23 16</ContactDetail>

        <ContactName>Leela ISSA</ContactName>
        <ContactDetail href="mailto:leela@filmtalents.com">
          leela@filmtalents.com
        </ContactDetail>
        <ContactDetail>+33 (0)7 86 04 08 12</ContactDetail>

        <Subtitle>{t("department_tv")}</Subtitle>
        <ContactName>Leela ISSA</ContactName>
        <ContactDetail href="mailto:leela@filmtalents.com">
          leela@filmtalents.com
        </ContactDetail>
        <ContactDetail>+33 (0)7 86 04 08 12</ContactDetail>

        <Subtitle>{t("legal")}</Subtitle>
        <ContactName>Ahmed BOINA</ContactName>
        <ContactDetail href="mailto:ahmed@filmtalents.com">
          ahmed@filmtalents.com
        </ContactDetail>
        <ContactDetail>+33 (0)7 86 55 10 63</ContactDetail>

        <ContactName>Marie de SAINTE HERMINE</ContactName>
        <ContactDetail href="mailto:marie@filmtalents.com">
          marie@filmtalents.com
        </ContactDetail>
        <ContactDetail>+33 (0)6 43 75 57 63</ContactDetail>

        <ContactName>Léa BIBERT</ContactName>
        <ContactDetail href="mailto:lea@filmtalents.com">
          lea@filmtalents.com
        </ContactDetail>
        <ContactDetail>+33 (0)7 89 37 82 53</ContactDetail>

        <Subtitle>{t("accounting")}</Subtitle>
        <ContactName>Isabelle LEQUITTE</ContactName>
        <ContactDetail href="mailto:isabelle@filmtalents.com">
          isabelle@filmtalents.com
        </ContactDetail>
        <ContactDetail>+33 (0)6 75 12 30 71</ContactDetail>

        <FooterText>@filmtalents</FooterText>
      </SplitSection>
    </>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "contact-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
