import React, { useRef, useEffect } from "react"
import styled, { css, keyframes } from "styled-components"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

import { usePageTransitionContext } from "../context/PageTransitionContext"

import { gsap } from "gsap"

type ImagePosition = "left" | "right"

type Props = {
  image: IGatsbyImageData
  imagePosition?: ImagePosition
  imageContainerWidth?: number
}

const BlurKeyframes = keyframes`
  0% {
    filter: blur(4px);
  }

  40% {
    filter: blur(0px);
  }

  60% {
    filter: blur(3px);
  }

  100% {
    filter: blur(0px);
  }
`

const Wrapper = styled.div<{
  imagePosition: ImagePosition
  imageContainerWidth: number
}>`
  position: relative;
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  align-items: center;

  @media (min-width: 768px) {
    grid-template-rows: 100%;
    grid-template-columns: ${({ imagePosition, imageContainerWidth }) =>
      imagePosition === "left"
        ? `${imageContainerWidth}% ${100 - imageContainerWidth}%`
        : `${100 - imageContainerWidth}% ${imageContainerWidth}%`};
  }
`

const ImageWrapper = styled.div<{
  imagePosition: ImagePosition
}>`
  position: relative;
  width: 100%;
  height: 100%;
  display: none;

  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    object-position: center;
    will-change: transform;

    img {
      transform: scale(1.05);
      transition: transform 700ms;
      filter: blur(4px);
    }
  }

  &.active {
    .gatsby-image-wrapper {
      img {
        transform: scale(1);
        animation: ${BlurKeyframes} 800ms ease-in forwards;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    ${({ imagePosition }) =>
      imagePosition === "right"
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `};
    background-color: white;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) {
    display: block;
    grid-column: ${({ imagePosition }) => (imagePosition === "right" ? 2 : 1)};
    grid-row: 1;
  }
`
const ContentWrapper = styled.div<{
  imagePosition: ImagePosition
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row: 1;
  padding: 200px 30px 100px;
  transform: translateY(-100px);
  opacity: 0;

  @media (min-width: 768px) {
    grid-row: 1;
    grid-column: ${({ imagePosition }) => (imagePosition === "right" ? 1 : 2)};
  }
`

const SplitSection: React.FC<Props> = ({
  image,
  imagePosition = "left",
  imageContainerWidth = 50,
  children,
}) => {
  const optimisedImage = getImage(image)
  const imageWrapperRef = useRef<HTMLDivElement | null>(null)
  const imageOverlayRef = useRef<HTMLDivElement | null>(null)
  const contentWrapperRef = useRef<HTMLDivElement | null>(null)
  const { exiting, entering, timeElapsed } = usePageTransitionContext()
  const loaded = useRef(false)

  useEffect(() => {
    let timeout = -1
    const duration = (timeElapsed?.current ?? 1500) / 1000
    const imageElement = imageWrapperRef.current?.querySelector(
      ".gatsby-image-wrapper"
    )
    if (entering) {
      if (
        imageOverlayRef.current &&
        imageElement &&
        contentWrapperRef.current
      ) {
        gsap.set(imageElement, {
          x: imagePosition === "left" ? "-50%" : "50%",
        })
        gsap.to(imageOverlayRef.current, {
          duration,
          ease: "power3.inOut",
          width: "0%",
        })
        gsap.to(imageElement, {
          duration,
          ease: "power3.inOut",
          x: 0,
        })

        gsap.to(contentWrapperRef.current, {
          duration,
          ease: "power3.inOut",
          y: 0,
          opacity: 1,
        })

        window.clearTimeout(timeout)
        timeout = window.setTimeout(() => {
          imageWrapperRef.current?.classList.add("active")
        }, 1000)
      }
      loaded.current = true
    }

    if (exiting && loaded.current) {
      if (
        imageOverlayRef.current &&
        imageElement &&
        contentWrapperRef.current
      ) {
        gsap.to(imageOverlayRef.current, {
          duration,
          ease: "power3.inOut",
          width: "100%",
        })

        gsap.to(imageElement, {
          duration,
          ease: "power3.inOut",
          x: imagePosition === "left" ? "-50%" : "50%",
        })

        gsap.to(contentWrapperRef.current, {
          duration,
          ease: "power3.inOut",
          y: -100,
          opacity: 0,
        })

        window.clearTimeout(timeout)
        timeout = window.setTimeout(() => {
          imageWrapperRef.current?.classList.remove("active")
        }, 350)
      }
    }

    return () => {
      window.clearTimeout(timeout)
    }
  }, [entering, exiting, imagePosition])

  return (
    <Wrapper
      imagePosition={imagePosition}
      imageContainerWidth={imageContainerWidth}
    >
      <ImageWrapper imagePosition={imagePosition} ref={imageWrapperRef}>
        {optimisedImage ? (
          <GatsbyImage image={optimisedImage} alt={`Film Talents`} />
        ) : null}
        <div className="overlay" ref={imageOverlayRef} />
      </ImageWrapper>

      <ContentWrapper imagePosition={imagePosition} ref={contentWrapperRef}>
        {children}
      </ContentWrapper>
    </Wrapper>
  )
}

export default SplitSection
